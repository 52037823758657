import React, { useState } from 'react';
import { animated, useSpring, config } from 'react-spring';
import { Waypoint } from 'react-waypoint';

const WayFadeUp = ({ children }) => {
  const [isVisible, setVisible] = useState(false);
  const { x, y } = useSpring({
    x: isVisible ? 1 : 0,
    y: isVisible ? 0 : 20,
    config: config.slow,
  });
  return (
    <animated.div
      style={{
        opacity: x.interpolate((x) => x),
        transform: y.interpolate((y) => `translate3d(0,${y}%, 0)`),
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
      }}
    >
      <Waypoint
        onEnter={() => {
          if (!isVisible) setVisible(true);
        }}
        topOffset='10%'
      />
      {children}
    </animated.div>
  );
};

export default WayFadeUp;
